export default {
  computed: {
    isMyGudangSelected() {
      if (this.isAdminGudang && this.myGudang) {
        const findGudang = this.selectedGudangs.find(gudang => gudang.value == this.myGudang.id)
        if (findGudang) {
          return true
        }

        return false
      }
      return false
    },
    rincianSalesFields() {
      return [
        { key: 'remove', label: '#' },
        { key: 'barang', label: 'Barang', sortable: true },
        { key: 'qty_request', label: 'Request Saya', sortable: true },
        { key: 'spv_request', label: 'Request SPV', sortable: true },
        { key: 'sumStock', label: 'Stok Tersedia', sortable: true },
        { key: 'satuan', label: 'Satuan', sortable: true },
        { key: 'keterangan', label: 'keterangan', sortable: true },
      ]
    },
    rincianSpvFields() {
      return [
        { key: 'remove', label: '#' },
        { key: 'barang', label: 'Barang', sortable: true },
        { key: 'qty_request', label: 'Request Sales', sortable: true },
        { key: 'satuan', label: 'Satuan', sortable: true },
        { key: 'keterangan', label: 'keterangan', sortable: true },
      ]
    },
    rincianGudangFields() {
      return [
        {
          key: 'details',
          label: 'Detail Stok',
        },
        { key: 'barang', label: 'Barang', sortable: true },
        { key: 'qty_request', label: 'Request Sales', sortable: true },
        { key: 'spv_request', label: 'Request SPV', sortable: true },
        { key: 'sumStock', label: 'Stok Disetujui Gudang', sortable: true },
        { key: 'satuan', label: 'Satuan', sortable: true },
        { key: 'keterangan', label: 'keterangan', sortable: true },
      ]
    },
    rincianDefaultFields() {
      return [
        { key: 'remove', label: '#' },
        { key: 'barang', label: 'Barang', sortable: true },
        { key: 'qty_request', label: 'Request Sales', sortable: true },
        { key: 'spv_request', label: 'Request SPV', sortable: true },
        { key: 'satuan', label: 'Satuan', sortable: true },
        { key: 'keterangan', label: 'keterangan', sortable: true },
      ]
    },
  },
  methods: {
    async getGudangSales(requestBarang) {
      const gudang = await this.$store.dispatch('gudang/getData', {
        karyawan_id: requestBarang.sales ? requestBarang.sales.id : null,
        jenis: 2,
      })

      if (gudang && gudang.length > 0) {
        return gudang[0]
      }

      return null
    },
    /**
         * Get tujuan prop filter by Gudang from rincian barang (KHUSUS GUDANG)
         * @params rincian barang obj
         *
        */
    getTujuanFromRincian(rincian) {
      if (rincian.tujuan) {
        const tujuans = []
        rincian.tujuan.map(tujuan => {
          if (this.isAdminGudang && !this.isSales && tujuan.gudang_id == this.myGudang.id) {
            tujuans.push(tujuan)
          } else if (this.isSales) {
            tujuans.push(tujuan)
          }
        })
        return tujuans.reduce((total, item) => total += item.qty_request, 0)
      }
      return 0
    },
    /**
         * // tapi jika belum nilai defaultnya adalah jumlah stok di n gudang per barang itu
         * @param {*} rincian
         */
    async getPenyimpananDefault(rincian) {
      const { barang } = rincian
      if (!barang) {
        return false
      }

      // get stock
      const stocks = await this.$store.dispatch('penyimpanan/getData', {
        barang_id: barang.id,
        gudang_id: this.myGudang.id,
      })
      rincian.stocks = stocks
      if (rincian.tujuan) {
        const tujuans = []
        rincian.tujuan.map(tujuan => {
          if (tujuan.gudang_id == this.myGudang.id) {
            tujuans.push(tujuan)
          }
        })
        return tujuans.reduce((total, item) => total += item.qty_real, 0)
      }
      return stocks ? stocks.reduce((total, item) => total += item.jumlah, 0) : 0
    },
    async getPenyimpananApprove(rincian) {
      let result = 0
      if (rincian.tujuan) { // jika sebelumnya sudah diinput oleh gudang, maka kesana aja
        const tujuans = []
        rincian.tujuan.map(tujuan => {
          if (tujuan.gudang_id == this.myGudang.id) {
            tujuans.push(tujuan)
          }
        })
        result = tujuans.reduce((total, item) => total += item.qty_real, 0)
      }

      // jika ditujuan pun masih 0, maka default ambil dari stock n gudang
      // if(result < 1) {
      result = await this.getPenyimpananDefault(rincian)
      // }
      return result
    },
    updateRincianFields(availableGudangs, role = 'sales') {
      switch (role) {
        case 'sales':
          break
        case 'spv':
          const fields = this.rincianSpvFields
          const gudangFields = availableGudangs.map(gudang => ({ key: gudang.key, label: gudang.label }))
          fields.splice(4, 0, ...gudangFields)
          return fields
          break
      }
    },
    getPenyimpananReal(rincian) { // KHUSUS SALES
      if (rincian.tujuan) {
        const tujuans = []
        rincian.tujuan.map(tujuan => {
          tujuans.push(tujuan)
        })
        // return tujuans.reduce((total, item) => total += item.qty_real, 0)
        return tujuans[tujuans.length - 1].qty_real
      }
      return 0
    },
    getSelectedGudang(rincians) {
      const selectedGudangs = []

      rincians.map(rincian => {
        if (rincian.tujuan) {
          rincian.tujuan.map(tujuan => {
            selectedGudangs.push(tujuan.gudang_id)
          })
        }
      })

      return selectedGudangs
    },
    setRincianDetail(rincians) { // for !spv to see request sales, request spv, and reqest gudang
      // get barangId
      const barangsId = rincians.map(rincian => (rincian.barang != null ? rincian.barang.id : null))
      const gudangId = this.myGudang.id

      // get permintaan stock spv dan jadikan prop
      rincians.map(async rincian => {
        rincian.stocks = []
        rincian.sumStock = 0
        rincian.spv_request = this.getTujuanFromRincian(rincian)

        // get apprvove gudang penyimpanan histor
        if (this.isAdminGudang && !this.isSales) {
          rincian.sumStock = await this.getPenyimpananApprove(rincian)
        }

        if (this.isSales) {
          rincian.sumStock = this.getPenyimpananReal(rincian)
        }
      })

      return rincians
    },
    setRincianGudang(rincians) { // for spv detailing rincian
      // check jika ada tujuan, maka tampilan per gudang nya
      const hasTujuan = rincians.some(rincian => rincian.tujuan != null)
      if (hasTujuan) {
        // store gudang for become new fields
        const availableGudangs = []

        // update fields per gudang
        rincians.map(rincian => {
          if (rincian.tujuan) {
            // search gudang for fields
            this.gudangs.map(gudang => {
              const findTujuan = rincian.tujuan.find(tujuan => tujuan.gudang_id == gudang.value)
              if (findTujuan) {
                const tujuanInGudang = this.selectedGudangs.find(sg => sg.value == findTujuan.gudang_id)
                if (!tujuanInGudang) { // dipaket if buat menghindari duplicate selected filter gudang
                  this.selectedGudangs.push({
                    value: findTujuan.gudang_id,
                    text: gudang.text,
                  })
                  this.rawSelectedGudangs.push({
                    value: findTujuan.gudang_id,
                    text: gudang.text,
                    new: true,
                  })
                }
                availableGudangs.push({
                  label: gudang.text,
                  value: findTujuan.gudang_id,
                  key: this.getShortName(gudang.text),
                  ...findTujuan,
                })
              }
            })
          }
        })

        if (availableGudangs.length > 0) {
          // update fields
          this.rincianFields = this.updateRincianFields(availableGudangs, 'spv')

          // update data
          rincians.map(rincian => {
            rincian.penyimpanan = [] // buat store field untuk gudang, 1 barang bisa jadi SPV ngisi untuk 1-2,... gudang
            rincian.spv_request = 0 // untuk ditampilkan sebagai jumlah request dari spv
            availableGudangs.map(gudang => {
              if (rincian.tujuan) {
                // tujuan propsper gudang
                const tujuanGudang = rincian.tujuan.filter(tujuan => tujuan.gudang_id == gudang.value)
                if (this.isSPV) {
                  rincian[gudang.key] = tujuanGudang.length > 0 ? tujuanGudang.reduce((total, item) => total += item.qty_request, 0) : 0
                }
                rincian.spv_request += tujuanGudang.length > 0 ? tujuanGudang.reduce((total, item) => total += item.qty_request, 0) : 0
              } else {
                rincian[gudang.key] = 0
              }
            })
          })
        }
      }

      return rincians
    },
  },
}
