<template>
    <b-modal title="Preview Pemilihan Stok" id="form-preview-stock" size="lg">
        <b-table :fields="fields" :items="stocks" class="w-full" responsive>
            <template #cell(no)="{index}">
                {{++index}}
            </template>
            <template #cell(is_error)="{item}">
                <i class="text-danger" v-if="item.is_error">{{item.message}}</i>
                <strong class="text-success" v-else>Stok sudah dipilih</strong>
            </template>
        </b-table>

        <template #modal-footer>
            <b-button variant="primary" @click.prevent="submit" :disabled="!isValidForm">Simpan</b-button>
        </template>
    </b-modal>
</template>
<script>
import {BModal, BTable, BButton} from 'bootstrap-vue'
export default {
    components: {
        BModal, BTable, BButton
    },
    data: () => ({
        fields: [
            {
                key: 'no',
                label: 'No'
            },
            {
                key: 'nama',
                label: 'Nama'
            },
            {
                key: 'request',
                label: 'Request SPV'
            },
            {
                key: 'sumStock',
                label: 'Stok Dipilih'
            },
            {
                key: 'is_error',
                label: 'Deskripsi'
            }
        ]
    }),
    methods: {
        submit() {
            if(!this.isValidForm) {
                this.displayError({
                    message: 'Harap perbaiki pemilihan stok gudang anda!'
                })
                return false
            }
            const payloads = []
            this.stocks.map(item => {
                payloads.push(item.data)
            })

            this.$emit('submit', payloads)
        }
    },
    computed: {
        isValidForm() {
            const thereError = this.stocks.some(item => item.is_error == true)
            if(thereError) {
                return false
            }

            return true
        },
        stocks() {
            const stocks = this.$store.state.requestbarang.selected_stocks
            return stocks.length > 0 ? JSON.parse( JSON.stringify(stocks) ) : []
        }
    }
}
</script>